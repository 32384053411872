import React from 'react'
import ModifiedHelmet from '../components/modified-helmet'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Page from "../components/page"

export default ({ data: {datoCmsPage: {seoMetaTags, widgets}} }) => (
  <Layout>
    <ModifiedHelmet seo={seoMetaTags} />
    <Page widgets={widgets} />
  </Layout>
)


export const query = graphql`
  query PageQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      title
      ...Widgets
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
